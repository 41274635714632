export type Point = { x: number; y: number };

export const distanceBetween = (point1: Point, point2: Point) => {
	return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
};

export const angleBetween = (point1: Point, point2: Point) => {
	return Math.atan2(point2.x - point1.x, point2.y - point1.y);
};

export const shadeColor = (color: string, percent: number) => {
	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = (R * (100 + percent)) / 100;
	G = (G * (100 + percent)) / 100;
	B = (B * (100 + percent)) / 100;

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
	const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
	const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

	return '#' + RR + GG + BB;
};

export const drawCheckeredBackground = (
	ctx: CanvasRenderingContext2D,
	config?: {
		invert?: boolean;
		lightColor?: string;
		darkColor?: string;
		step?: number;
	}
) => {
	if (!ctx) {
		console.log('returning from drawCheckeredBackground in BrushRepresentation because !ctx');
		return;
	}

	const w = ctx.canvas.width;
	const h = ctx.canvas.height;

	const darkColor = config?.darkColor ?? '#1A131A';
	const lightColor = config?.lightColor ?? '#2C1D26';
	const inverted = config?.invert ?? false;
	const step = config?.step ?? 6;

	let invert = inverted;

	ctx.fillStyle = lightColor;
	ctx.globalAlpha = 1.0;
	ctx.lineWidth = 0.0;
	ctx.fillRect(0, 0, w, h);
	for (let i = 0; i < w; i++) {
		if (i % 2 === 0) {
			invert = inverted;
		} else {
			invert = !inverted;
		}
		for (let j = 0; j < h; j++) {
			ctx.fillStyle = invert ? lightColor : darkColor;
			ctx.strokeStyle = invert ? lightColor : darkColor;
			ctx.fillRect(i * step, j * step, step, step);
			invert = !invert;
		}
	}
};

export const copyCanvas = (canvas: HTMLCanvasElement) => {
	const newCanvas = document.createElement('canvas');
	newCanvas.width = canvas.width;
	newCanvas.height = canvas.height;
	const ctx = newCanvas.getContext('2d');
	if (!ctx) {
		throw new Error('Could not get context from newCanvas');
	}
	ctx.drawImage(canvas, 0, 0);
	return newCanvas;
};

export const combineCanvases = (canvas1: HTMLCanvasElement, canvas2: HTMLCanvasElement) => {
	const newCanvas = document.createElement('canvas');
	newCanvas.width = canvas1.width;
	newCanvas.height = canvas1.height;
	const ctx = newCanvas.getContext('2d');
	if (!ctx) {
		throw new Error('Could not get context from newCanvas');
	}
	ctx.drawImage(canvas1, 0, 0);
	ctx.drawImage(canvas2, 0, 0);
	return newCanvas;
};

export const blur = async (base64Data: string, radius: number, tempCanvas: HTMLCanvasElement) => {
	const image = new Image();
	image.src = base64Data;

	// wait for image to load
	await new Promise<void>((resolve) => {
		image.onload = () => resolve();
	});

	tempCanvas.width = image.width;
	tempCanvas.height = image.height;
	const ctx = tempCanvas.getContext('2d');
	if (!ctx) {
		throw new Error('Could not get context from tempCanvas');
	}

	ctx.drawImage(image, 0, 0);
	ctx.filter = `blur(${radius}px)`;
	ctx.drawImage(image, 0, 0);

	return tempCanvas.toDataURL();
};

export const overlaySrcImageUponDestImage = (
	mask: HTMLCanvasElement,
	destImage: HTMLImageElement,
	srcImage: HTMLImageElement
) => {
	const canvas = document.createElement('canvas');
	canvas.width = destImage.width;
	canvas.height = destImage.height;
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Could not get context from canvas');
	}

	const tempCanvas = document.createElement('canvas');
	tempCanvas.width = destImage.width;
	tempCanvas.height = destImage.height;
	const tempCtx = tempCanvas.getContext('2d');
	if (!tempCtx) {
		throw new Error('Could not get context from tempCanvas');
	}

	// 1. draw the dest image
	ctx.drawImage(destImage, 0, 0);
	// 2. use the tempCanvas to draw srcImage only on the mask
	tempCtx.drawImage(mask, 0, 0);
	tempCtx.globalCompositeOperation = 'source-in';
	tempCtx.drawImage(srcImage, 0, 0);
	// 3. draw the tempCanvas on the dest image
	ctx.drawImage(tempCanvas, 0, 0);

	return canvas.toDataURL();
};

export function shrinkMask(canvas: HTMLCanvasElement, pixels: number): HTMLCanvasElement {
	// Create a new canvas element
	const shrunkCanvas = document.createElement('canvas');
	shrunkCanvas.width = canvas.width;
	shrunkCanvas.height = canvas.height;

	const context = shrunkCanvas.getContext('2d');
	if (!context) {
		throw new Error('Cannot get context from canvas');
	}

	// Draw the original image onto the canvas, shrunk by 'pixels' amount
	context.drawImage(canvas, pixels, pixels, canvas.width - 2 * pixels, canvas.height - 2 * pixels);

	return shrunkCanvas;
}

export function categorizeTransparency(canvas: HTMLCanvasElement) {
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Cannot get canvas context');
	}

	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const data = imageData.data;

	let transparent = 0;
	let semiTransparent = 0;
	let opaque = 0;
	for (let i = 3; i < data.length; i += 4) {
		if (data[i] === 0) {
			transparent++;
		} else if (data[i] > 0 && data[i] < 255) {
			semiTransparent++;
		} else {
			opaque++;
		}
	}

	return {
		transparent,
		semiTransparent,
		opaque
	};
}

export function hasPartialTransparency(canvas: HTMLCanvasElement) {
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Cannot get canvas context');
	}

	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const data = imageData.data;

	for (let i = 3; i < data.length; i += 4) {
		if (data[i] > 0 && data[i] < 255) {
			return true;
		}
	}

	return false;
}

export function setPartialTransparencyToMax(canvas: HTMLCanvasElement) {
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Cannot get canvas context');
	}

	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const data = imageData.data;

	let modified = false;

	for (let i = 3; i < data.length; i += 4) {
		if (data[i] === 0) {
			continue;
		}
		data[i] = 255;
		modified = true;
	}

	// Only write the image data back to the canvas if it was modified
	if (modified) {
		ctx.putImageData(imageData, 0, 0);
	}
}

export function isWebGLAvailable() {
	try {
		const canvas = document.createElement('canvas');
		return !!(
			window.WebGLRenderingContext &&
			(canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
		);
	} catch (e) {
		return false;
	}
}

export async function resizeImageUrl(imageUrl: string, newWidth: number, newHeight: number) {
	const image = new Image();
	image.src = imageUrl;

	// wait for image to load
	await new Promise<void>((resolve) => {
		image.onload = () => resolve();
	});

	const canvas = document.createElement('canvas');
	canvas.width = newWidth;
	canvas.height = newHeight;
	const ctx = canvas.getContext('2d');
	if (!ctx) {
		throw new Error('Could not get context from canvas');
	}

	ctx.drawImage(image, 0, 0, newWidth, newHeight);

	return canvas.toDataURL();
}

// Safari holds on to the canvas for a little bit and has a low memory limit.
// this tricks Safari in to replacing the canvas in its storage with our compressed version.
// See: https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
export function releaseCanvas(canvas: HTMLCanvasElement) {
	canvas.width = 1;
	canvas.height = 1;
	const ctx = canvas.getContext('2d');
	ctx && ctx.clearRect(0, 0, 1, 1);
}
